import { Router } from 'vue-router'

// 在新标签页打开
export const newTabTo = (router: Router, url: string, query: any = {}) => window.open(router.resolve({ path: url, query }).href)

export const buildPageHTML = (data: any[], router: Router) => {
  const pageDiv = document.createElement('div')
  pageDiv.className = 'page'

  // 遍历数据列表
  data.forEach((item) => {
    // 创建list-item容器
    const listItemDiv = document.createElement('div')
    listItemDiv.className = 'list-item'

    // 设置点击事件
    listItemDiv.addEventListener('click', () => {
      newTabTo(router, '/DetailPage', { id: item.lawVersionId })
    })

    // 创建name容器
    const nameDiv = document.createElement('div')
    nameDiv.className = 'name'
    nameDiv.textContent = item.lawName

    // 创建time容器
    const timeDiv = document.createElement('div')
    timeDiv.className = 'time'
    timeDiv.textContent = item.publishDate

    // 组装DOM结构
    listItemDiv.appendChild(nameDiv)
    listItemDiv.appendChild(timeDiv)
    pageDiv.appendChild(listItemDiv)
  })

  return pageDiv
}
